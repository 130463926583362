import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth, googleProvider, db } from '../firebase'
import { User, onAuthStateChanged, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'

interface AuthContextType {
  currentUser: User | null
  isModerator: boolean
  login: (email: string, password: string) => Promise<void>
  register: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  signInWithGoogle: () => Promise<void>
  updateUserProfile: (data: any) => Promise<void>
  userNeedsProfileUpdate: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [isModerator, setIsModerator] = useState(false)
  const [userNeedsProfileUpdate, setUserNeedsProfileUpdate] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user)
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid))
        if (!userDoc.exists() || !userDoc.data().company) {
          setUserNeedsProfileUpdate(true)
        } else {
          setUserNeedsProfileUpdate(false)
        }

        // Check if user is a moderator
        const moderatorDoc = await getDoc(doc(db, 'moderators', user.uid))
        setIsModerator(moderatorDoc.exists() && moderatorDoc.data().isModerator === true)
      } else {
        setIsModerator(false)
      }
    })
    return unsubscribe
  }, [])

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const register = async (email: string, password: string) => {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    await setDoc(doc(db, 'users', result.user.uid), {
      email: result.user.email,
    })
    setUserNeedsProfileUpdate(true)
  }

  const logout = () => {
    return signOut(auth)
  }

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)
      const user = result.user
      
      const userDocRef = doc(db, 'users', user.uid)
      const userDoc = await getDoc(userDocRef)
      
      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
        })
        setUserNeedsProfileUpdate(true)
      } else if (!userDoc.data().company) {
        setUserNeedsProfileUpdate(true)
      }
    } catch (error: any) {
      console.error("Error signing in with Google", error)
      if (error.code === 'auth/unauthorized-domain') {
        alert('This domain is not authorized for Google Sign-In. Please use email/password sign-in for now.')
      }
    }
  }

  const updateUserProfile = async (data: any) => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid)
      await updateDoc(userDocRef, data)
      setUserNeedsProfileUpdate(false)
    }
  }

  const value = {
    currentUser,
    isModerator,
    login,
    register,
    logout,
    signInWithGoogle,
    updateUserProfile,
    userNeedsProfileUpdate
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}