import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase'

type PostType = 'general' | 'workCultureReview'

const CreatePost: React.FC = () => {
  const { currentUser } = useAuth()
  const { register, handleSubmit, setValue, watch } = useForm()
  const navigate = useNavigate()
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [postType, setPostType] = useState<PostType>('workCultureReview')

  const watchPostType = watch('postType')

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid))
        if (userDoc.exists()) {
          const userData = userDoc.data()
          setValue('company', userData.company || '')
          setValue('salary', userData.salary || '')
        }
      }
    }
    fetchUserData()
  }, [currentUser, setValue])

  useEffect(() => {
    setPostType(watchPostType)
  }, [watchPostType])

  const onSubmit = async (data: any) => {
    setError('')
    setSuccess('')
    try {
      const { 
        company, 
        salary, 
        postType,
        title,
        content,
        workHours,
        rudeBehavior,
        weekendWork,
        leavesPerYear,
        appraisals,
        interviewRounds
      } = data
      
      const postData: any = {
        company,
        salary,
        postType,
        authorId: currentUser?.uid,
        createdAt: serverTimestamp(),
        upvotes: 0
      }

      if (postType === 'general') {
        postData.title = title
        postData.content = content
      } else if (postType === 'workCultureReview') {
        postData.workCultureReview = {
          workHours,
          rudeBehavior,
          weekendWork,
          leavesPerYear,
          appraisals,
          interviewRounds
        }
      }

      await addDoc(collection(db, 'posts'), postData)
      setSuccess('Post created successfully!')
      navigate('/')
    } catch (err: any) {
      console.error('Error creating post:', err)
      setError(`Failed to create post: ${err.message}`)
    }
  }

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Create a New Post</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="postType" className="block mb-1">Post Type</label>
          <select
            id="postType"
            {...register('postType')}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          >
            <option value="general">General Post</option>
            <option value="workCultureReview">Work Culture Review</option>
          </select>
        </div>

        <div>
          <label htmlFor="company" className="block mb-1">Company</label>
          <input
            type="text"
            id="company"
            {...register('company', { required: true })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
        <div>
          <label htmlFor="salary" className="block mb-1">Annual Salary (in INR LPA)</label>
          <input
            type="text"
            id="salary"
            {...register('salary', { required: true })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
        
        {postType === 'general' && (
          <>
            <div>
              <label htmlFor="title" className="block mb-1">Title</label>
              <input
                type="text"
                id="title"
                {...register('title', { required: postType === 'general' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              />
            </div>
            <div>
              <label htmlFor="content" className="block mb-1">Content</label>
              <textarea
                id="content"
                {...register('content', { required: postType === 'general' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                rows={5}
              ></textarea>
            </div>
          </>
        )}
        
        {postType === 'workCultureReview' && (
          <>
            <div>
              <label htmlFor="workHours" className="block mb-1">How many hours per day did you/your team actually work?</label>
              <select
                id="workHours"
                {...register('workHours', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              >
                <option value="">Select an option</option>
                <option value="less than 4 hours">Less than 4 hours</option>
                <option value="4 to 6 hours">4 to 6 hours</option>
                <option value="6 to 8 hours">6 to 8 hours</option>
                <option value="8 to 10 hours">8 to 10 hours</option>
                <option value="10 to 12 hours">10 to 12 hours</option>
                <option value="12 to 14 hours">12 to 14 hours</option>
                <option value="14 hours +">14 hours +</option>
              </select>
            </div>
            <div>
              <label htmlFor="rudeBehavior" className="block mb-1">Were you ever shouted at or received passive aggressive / inappropriate/rude remarks from your manager/seniors?</label>
              <select
                id="rudeBehavior"
                {...register('rudeBehavior', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              >
                <option value="">Select an option</option>
                <option value="Never">Never</option>
                <option value="Once">Once</option>
                <option value="A few times">A few times</option>
                <option value="A lot of times">A lot of times</option>
                <option value="Almost daily">Almost daily</option>
              </select>
            </div>
            <div>
              <label htmlFor="weekendWork" className="block mb-1">Are you expected to work on weekends or outside working hours?</label>
              <select
                id="weekendWork"
                {...register('weekendWork', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              >
                <option value="">Select an option</option>
                <option value="Never">Never</option>
                <option value="Very few times">Very few times</option>
                <option value="Sometimes">Sometimes</option>
                <option value="All the time">All the time</option>
              </select>
            </div>
            <div>
              <label htmlFor="leavesPerYear" className="block mb-1">How many leaves per year do you realistically get?</label>
              <input
                type="number"
                id="leavesPerYear"
                {...register('leavesPerYear', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                min="0"
              />
            </div>
            <div>
              <label htmlFor="appraisals" className="block mb-1">How stingy or generous are they during appraisals?</label>
              <select
                id="appraisals"
                {...register('appraisals', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              >
                <option value="">Select an option</option>
                <option value="Very stingy (flat or less than 5% hikes)">Very stingy (flat or less than 5% hikes)</option>
                <option value="Somewhat stingy (5 to 10% hikes)">Somewhat stingy (5 to 10% hikes)</option>
                <option value="Not stingy (10 to 25% hikes)">Not stingy (10 to 25% hikes)</option>
                <option value="Generous (>25% hikes)">Generous (&gt;25% hikes)</option>
              </select>
            </div>
            <div>
              <label htmlFor="interviewRounds" className="block mb-1">How many interview rounds happened when getting hired here?</label>
              <input
                type="number"
                id="interviewRounds"
                {...register('interviewRounds', { required: postType === 'workCultureReview' })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                min="1"
              />
            </div>
          </>
        )}
        
        <button type="submit" className="w-full btn-primary">
          Create Post
        </button>
      </form>
    </div>
  )
}

export default CreatePost