import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import Header from './components/Header'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import CreatePost from './pages/CreatePost'
import PostDetails from './pages/PostDetails'
import LoginPrompt from './pages/LoginPrompt'
import UpdateProfile from './pages/UpdateProfile'
import ProfileInfoModal from './components/ProfileInfoModal'

function AppContent() {
  const { userNeedsProfileUpdate } = useAuth()

  return (
    <Router>
      <div className="min-h-screen bg-cream">
        <Header />
        <main className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/create-post" element={<CreatePost />} />
            <Route path="/post/:id" element={<PostDetails />} />
            <Route path="/login-prompt" element={<LoginPrompt />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
          </Routes>
        </main>
        {userNeedsProfileUpdate && <ProfileInfoModal onClose={() => {}} />}
      </div>
    </Router>
  )
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  )
}

export default App