import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { doc, getDoc, updateDoc, arrayUnion, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase'
import { useAuth } from '../contexts/AuthContext'
import { format } from 'date-fns'
import { ThumbsUp, Trash2, Edit, Star } from 'lucide-react'

const CommentForm = ({ onSubmit }) => {
  const [commentInput, setCommentInput] = useState('')
  const textareaRef = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (commentInput.trim()) {
      onSubmit(commentInput)
      setCommentInput('')
    }
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit} className="mb-6">
      <textarea
        ref={textareaRef}
        value={commentInput}
        onChange={(e) => setCommentInput(e.target.value)}
        placeholder="Write a comment..."
        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-300"
        rows={3}
      ></textarea>
      <button
        type="submit"
        className="mt-2 btn-primary"
      >
        Post Comment
      </button>
    </form>
  )
}

const PostDetails = () => {
  const { id } = useParams()
  const [post, setPost] = useState(null)
  const [commentAuthors, setCommentAuthors] = useState({})
  const { currentUser, isModerator } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPost = async () => {
      if (id) {
        const postDoc = await getDoc(doc(db, 'posts', id))
        if (postDoc.exists()) {
          const postData = postDoc.data()
          const userDoc = await getDoc(doc(db, 'users', postData.authorId))
          const userData = userDoc.data()
          setPost({
            id: postDoc.id,
            ...postData,
            authorDesignation: userData?.designation || 'Unknown',
            authorCompany: userData?.company || 'Unknown',
            createdAt: postData.createdAt?.toDate() || new Date(),
            comments: postData.comments || []
          })

          // Fetch comment authors
          const commentAuthorsData = {}
          for (const comment of postData.comments || []) {
            if (!commentAuthorsData[comment.authorId]) {
              const commentAuthorDoc = await getDoc(doc(db, 'users', comment.authorId))
              commentAuthorsData[comment.authorId] = commentAuthorDoc.data()
            }
          }
          setCommentAuthors(commentAuthorsData)
        } else {
          navigate('/')
        }
      }
    }
    fetchPost()
  }, [id, navigate])

  const handleUpvote = async () => {
    if (!currentUser) {
      navigate('/login-prompt')
      return
    }
    try {
      const postRef = doc(db, 'posts', id)
      await updateDoc(postRef, {
        upvotes: (post.upvotes || 0) + 1
      })
      setPost({ ...post, upvotes: (post.upvotes || 0) + 1 })
    } catch (error) {
      console.error('Error upvoting post:', error)
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deleteDoc(doc(db, 'posts', id))
        navigate('/')
      } catch (error) {
        console.error('Error deleting post:', error)
      }
    }
  }

  const handleEdit = () => {
    navigate(`/edit-post/${id}`)
  }

  const handleBoost = async () => {
    try {
      const postRef = doc(db, 'posts', id)
      await updateDoc(postRef, {
        boosted: true,
        boostTimestamp: serverTimestamp()
      })
      setPost({ ...post, boosted: true, boostTimestamp: new Date() })
    } catch (error) {
      console.error('Error boosting post:', error)
    }
  }

  const handleAddComment = async (commentContent) => {
    if (!currentUser) {
      navigate('/login-prompt')
      return
    }
    try {
      const postRef = doc(db, 'posts', id)
      const newComment = {
        content: commentContent,
        authorId: currentUser.uid,
        createdAt: serverTimestamp()
      }
      await updateDoc(postRef, {
        comments: arrayUnion(newComment)
      })
      setPost({
        ...post,
        comments: [...(post.comments || []), { ...newComment, createdAt: new Date() }]
      })
    } catch (error) {
      console.error('Error adding comment:', error)
    }
  }

  if (!post) {
    return <div>Loading...</div>
  }

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-3xl font-bold mb-2">
              {post.postType === 'general' ? post.title : `Work Culture Review: ${post.company}`}
            </h1>
            <div className="text-sm text-gray-600">
              Posted by {post.authorDesignation} at {post.authorCompany} on {format(post.createdAt, 'MMMM d, yyyy')}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleUpvote}
              className="flex items-center space-x-1 text-gray-500 hover:text-primary transition-colors duration-300"
            >
              <ThumbsUp size={20} />
              <span>{post.upvotes || 0}</span>
            </button>
            {(currentUser?.uid === post.authorId || isModerator) && (
              <>
                <button
                  onClick={handleEdit}
                  className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
                >
                  <Edit size={20} />
                </button>
                <button
                  onClick={handleDelete}
                  className="text-red-500 hover:text-red-700 transition-colors duration-300"
                >
                  <Trash2 size={20} />
                </button>
              </>
            )}
            {isModerator && !post.boosted && (
              <button
                onClick={handleBoost}
                className="text-yellow-500 hover:text-yellow-700 transition-colors duration-300"
              >
                <Star size={20} />
              </button>
            )}
          </div>
        </div>
        {post.postType === 'general' ? (
          <p className="text-gray-700 mb-4">{post.content}</p>
        ) : (
          <div className="grid grid-cols-2 gap-4 mb-4">
            {Object.entries(post.workCultureReview || {}).map(([key, value]) => (
              <div key={key} className="bg-[#F3EBD3] p-3 rounded-lg">
                <p className="font-semibold">{key}</p>
                <p>{value}</p>
              </div>
            ))}
          </div>
        )}
        <div className="mt-6">
          <h2 className="text-2xl font-bold mb-4">Comments</h2>
          {post.comments && post.comments.length > 0 ? (
            post.comments.map((comment, index) => (
              <div key={index} className="bg-[#F3EBD3] p-4 rounded-lg mb-4">
                <p className="text-gray-700">{comment.content}</p>
                <div className="text-sm text-gray-600 mt-2">
                  Posted by {commentAuthors[comment.authorId]?.designation || 'Unknown'} at {commentAuthors[comment.authorId]?.company || 'Unknown'} on {format(comment.createdAt.toDate(), 'MMMM d, yyyy')}
                </div>
              </div>
            ))
          ) : (
            <p>No comments yet.</p>
          )}
        </div>
        {currentUser && <CommentForm onSubmit={handleAddComment} />}
      </div>
    </div>
  )
}

export default PostDetails