import React from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../contexts/AuthContext'

interface ProfileInfoModalProps {
  onClose: () => void
}

const ProfileInfoModal: React.FC<ProfileInfoModalProps> = ({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { updateUserProfile } = useAuth()

  const onSubmit = async (data: any) => {
    try {
      await updateUserProfile(data)
      onClose()
    } catch (error) {
      console.error('Error updating profile:', error)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Complete Your Profile</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="company" className="block mb-1">Company</label>
            <input
              type="text"
              id="company"
              {...register('company', { required: 'Company is required' })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            {errors.company && <p className="text-red-500 text-sm mt-1">{errors.company.message as string}</p>}
          </div>
          <div>
            <label htmlFor="designation" className="block mb-1">Designation</label>
            <input
              type="text"
              id="designation"
              {...register('designation', { required: 'Designation is required' })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            {errors.designation && <p className="text-red-500 text-sm mt-1">{errors.designation.message as string}</p>}
          </div>
          <div>
            <label htmlFor="salary" className="block mb-1">Annual Salary (in INR LPA)</label>
            <input
              type="text"
              id="salary"
              {...register('salary', {
                required: 'Salary is required',
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: 'Please enter a valid salary (e.g., 5 or 5.5)'
                }
              })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            {errors.salary && <p className="text-red-500 text-sm mt-1">{errors.salary.message as string}</p>}
          </div>
          <button type="submit" className="w-full btn-primary">
            Save Profile
          </button>
        </form>
      </div>
    </div>
  )
}

export default ProfileInfoModal