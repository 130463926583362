import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyACXR3t7oJtKQ1ad4n-szjo0kNtmvAVzE4",
  authDomain: "hiring-in-the-time-of-corona.firebaseapp.com",
  databaseURL: "https://hiring-in-the-time-of-corona.firebaseio.com",
  projectId: "hiring-in-the-time-of-corona",
  storageBucket: "hiring-in-the-time-of-corona.appspot.com",
  messagingSenderId: "55948440403",
  appId: "1:55948440403:web:6edf3e28aeb252cb71a7a6",
  measurementId: "G-7N91FWEEBP"
}

console.log('Initializing Firebase with config:', firebaseConfig)

const app = initializeApp(firebaseConfig)
console.log('Firebase app initialized:', app)

export const auth = getAuth(app)
console.log('Firebase auth initialized:', auth)

export const db = getFirestore(app)
console.log('Firestore initialized:', db)

export const googleProvider = new GoogleAuthProvider()