import React from 'react'
import { Link } from 'react-router-dom'

const LoginPrompt: React.FC = () => {
  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4">Login Required</h2>
      <p className="mb-6">Only logged-in users can perform this action. Please log in to continue.</p>
      <div className="flex justify-between">
        <Link
          to="/login"
          className="btn-primary"
        >
          Log In
        </Link>
        <Link
          to="/register"
          className="bg-gray-200 text-black py-2 px-4 rounded-full hover:bg-gray-300 transition-all duration-300"
        >
          Register
        </Link>
      </div>
    </div>
  )
}

export default LoginPrompt