import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { collection, query, orderBy, getDocs, doc, updateDoc, increment, getDoc, deleteDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { useAuth } from '../contexts/AuthContext'
import { format } from 'date-fns'
import { ThumbsUp, MessageCircle, Clock, AlertTriangle, Calendar, DollarSign, Users, Briefcase, Trash2, Edit, Star, ChevronUp } from 'lucide-react'

const Home: React.FC = () => {
  const [posts, setPosts] = useState<any[]>([])
  const [activeTab, setActiveTab] = useState<'popular' | 'recent'>('popular')
  const { currentUser, isModerator } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    fetchPosts()
  }, [activeTab])

  const fetchPosts = async () => {
    try {
      const postsCollection = collection(db, 'posts')
      const q = activeTab === 'popular'
        ? query(postsCollection, orderBy('upvotes', 'desc'))
        : query(postsCollection, orderBy('createdAt', 'desc'))
      const querySnapshot = await getDocs(q)
      const fetchedPosts = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
        const postData = docSnapshot.data()
        let userData = {}
        if (postData.authorId) {
          const userDocRef = doc(db, 'users', postData.authorId)
          const userDocSnapshot = await getDoc(userDocRef)
          if (userDocSnapshot.exists()) {
            userData = userDocSnapshot.data()
          }
        }
        return {
          id: docSnapshot.id,
          ...postData,
          authorDesignation: userData?.designation || 'Unknown',
          authorCompany: userData?.company || 'Unknown',
          createdAt: postData.createdAt?.toDate() || new Date(),
          boosted: postData.boosted || false,
          boostTimestamp: postData.boostTimestamp?.toDate() || null
        }
      }))
      // Check if boost has expired (24 hours) and sort boosted posts to the top
      const now = new Date()
      fetchedPosts.forEach(post => {
        if (post.boosted && post.boostTimestamp) {
          const boostExpiration = new Date(post.boostTimestamp.getTime() + 24 * 60 * 60 * 1000)
          if (now > boostExpiration) {
            post.boosted = false
          }
        }
      })
      fetchedPosts.sort((a, b) => {
        if (a.boosted && !b.boosted) return -1
        if (!a.boosted && b.boosted) return 1
        return 0
      })
      setPosts(fetchedPosts)
    } catch (error) {
      console.error('Error fetching posts:', error)
    }
  }

  const handleUpvote = async (postId: string) => {
    if (!currentUser) {
      navigate('/login-prompt')
      return
    }

    try {
      const postRef = doc(db, 'posts', postId)
      await updateDoc(postRef, {
        upvotes: increment(1)
      })
      fetchPosts()
    } catch (error) {
      console.error('Error upvoting post:', error)
    }
  }

  const handleDelete = async (postId: string) => {
    if (confirm('Are you sure you want to delete this post?')) {
      try {
        await deleteDoc(doc(db, 'posts', postId))
        fetchPosts()
      } catch (error) {
        console.error('Error deleting post:', error)
      }
    }
  }

  const handleEdit = (postId: string) => {
    navigate(`/edit-post/${postId}`)
  }

  const handleBoost = async (postId: string) => {
    try {
      const postRef = doc(db, 'posts', postId)
      await updateDoc(postRef, {
        boosted: true,
        boostTimestamp: new Date()
      })
      fetchPosts()
    } catch (error) {
      console.error('Error boosting post:', error)
    }
  }

  const navigateToPost = (postId: string) => {
    navigate(`/post/${postId}`)
  }

  const renderWorkCultureReview = (review: any) => {
    const reviewItems = [
      { icon: Clock, label: 'Work Hours', value: review.workHours },
      { icon: AlertTriangle, label: 'Rude Behavior', value: review.rudeBehavior },
      { icon: Calendar, label: 'Weekend Work', value: review.weekendWork },
      { icon: Calendar, label: 'Leaves per Year', value: review.leavesPerYear },
      { icon: DollarSign, label: 'Appraisals', value: review.appraisals },
      { icon: Users, label: 'Interview Rounds', value: review.interviewRounds }
    ]

    return (
      <div className="grid grid-cols-2 gap-4 mt-4">
        {reviewItems.map((item, index) => (
          <div key={index} className="flex items-center bg-[#F3EBD3] p-3 rounded-lg">
            <item.icon className="text-primary mr-3" size={24} />
            <div>
              <p className="text-sm font-semibold">{item.label}</p>
              <p className="text-sm">{item.value}</p>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <div className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded-full transition-all duration-300 ${activeTab === 'popular' ? 'bg-primary text-white' : 'bg-[#F3EBD3] text-black'}`}
          onClick={() => setActiveTab('popular')}
        >
          Popular
        </button>
        <button
          className={`px-4 py-2 rounded-full transition-all duration-300 ${activeTab === 'recent' ? 'bg-primary text-white' : 'bg-[#F3EBD3] text-black'}`}
          onClick={() => setActiveTab('recent')}
        >
          Recent Discussions
        </button>
      </div>
      <div className="grid gap-6">
        {posts.map(post => (
          <div key={post.id} className={`flex bg-white rounded-lg shadow-md ${post.boosted ? 'border-2 border-yellow-400' : ''}`}>
            <div className="flex flex-col items-center justify-start p-4 bg-[#F3EBD3] rounded-l-lg">
              <button
                onClick={() => handleUpvote(post.id)}
                className="text-gray-500 hover:text-primary transition-colors duration-300"
              >
                <ChevronUp size={36} />
              </button>
              <span className="font-bold text-lg mt-2">{post.upvotes || 0}</span>
            </div>
            <div className="flex-grow p-6">
              <div className="flex justify-between items-start mb-4">
                <div className="flex-grow">
                  <Link to={`/post/${post.id}`} className="text-xl font-bold mb-2 hover:text-primary transition-colors duration-300">
                    {post.postType === 'general' ? post.title : `Work Culture Review: ${post.company}`}
                  </Link>
                  <div className="flex items-center text-sm text-gray-500 mt-1">
                    <Briefcase size={16} className="mr-1" />
                    <span className="mr-2">Posted by {post.authorDesignation} at {post.authorCompany}</span>
                    <span>{format(post.createdAt, 'MMM d, yyyy')}</span>
                  </div>
                </div>
                <div className="flex items-center">
                  {isModerator && (
                    <>
                      <button
                        onClick={() => handleDelete(post.id)}
                        className="text-red-500 hover:text-red-700 transition-colors duration-300 ml-3"
                      >
                        <Trash2 size={20} />
                      </button>
                      <button
                        onClick={() => handleEdit(post.id)}
                        className="text-blue-500 hover:text-blue-700 transition-colors duration-300 ml-3"
                      >
                        <Edit size={20} />
                      </button>
                      {!post.boosted && (
                        <button
                          onClick={() => handleBoost(post.id)}
                          className="text-yellow-500 hover:text-yellow-700 transition-colors duration-300 ml-3"
                        >
                          <Star size={20} />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              {post.postType === 'general' ? (
                <p className="text-gray-600 mb-4">{post.content}</p>
              ) : post.workCultureReview ? (
                renderWorkCultureReview(post.workCultureReview)
              ) : (
                <p className="text-gray-600 mb-4">Work culture review details not available</p>
              )}
              <div className="flex justify-between items-center mt-4">
                <div className="flex space-x-2">
                  <span className="bg-[#5A01D8] text-white px-2 py-1 rounded-full text-xs">
                    {post.company || 'Company Not Available'}
                  </span>
                  <span className="bg-[#FF9900] text-white px-2 py-1 rounded-full text-xs">
                    {post.salary ? `${post.salary} LPA` : 'Salary Not Available'}
                  </span>
                </div>
                <button
                  onClick={() => navigateToPost(post.id)}
                  className="flex items-center text-gray-500 hover:text-primary transition-colors duration-300"
                >
                  <MessageCircle size={20} className="mr-1" />
                  <span>{post.comments?.length || 0} Comments</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {currentUser && (
        <Link
          to="/create-post"
          className="block mt-6 btn-primary text-center"
        >
          Create New Post
        </Link>
      )}
    </div>
  )
}

export default Home