import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { MessageCircle, LogIn, LogOut, UserPlus, PlusCircle, UserCog } from 'lucide-react'

const Header: React.FC = () => {
  const { currentUser, logout } = useAuth()

  return (
    <header className="bg-black text-cream">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <Link to="/" className="text-2xl font-bold flex items-center">
          <MessageCircle className="mr-2" />
          Culture Reviews
        </Link>
        <nav>
          {currentUser ? (
            <div className="flex items-center space-x-4">
              <Link to="/create-post" className="btn-primary flex items-center">
                <PlusCircle className="mr-1" size={18} />
                Create Post
              </Link>
              <Link to="/update-profile" className="btn-primary flex items-center">
                <UserCog className="mr-1" size={18} />
                Update Profile
              </Link>
              <button onClick={() => logout()} className="btn-primary flex items-center">
                <LogOut className="mr-1" size={18} />
                Logout
              </button>
            </div>
          ) : (
            <div className="flex items-center space-x-4">
              <Link to="/login" className="btn-primary flex items-center">
                <LogIn className="mr-1" size={18} />
                Login
              </Link>
              <Link to="/register" className="btn-primary flex items-center">
                <UserPlus className="mr-1" size={18} />
                Register
              </Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  )
}

export default Header