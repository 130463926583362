import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../contexts/AuthContext'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { db } from '../firebase'

type FormData = {
  company: string
  designation: string
  salary: string
}

const UpdateProfile: React.FC = () => {
  const { currentUser } = useAuth()
  const { register, handleSubmit, setValue } = useForm<FormData>()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid))
        if (userDoc.exists()) {
          const userData = userDoc.data()
          setValue('company', userData.company || '')
          setValue('designation', userData.designation || '')
          setValue('salary', userData.salary || '')
        }
      }
    }
    fetchUserData()
  }, [currentUser, setValue])

  const onSubmit = async (data: FormData) => {
    try {
      if (currentUser) {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          company: data.company,
          designation: data.designation,
          salary: data.salary
        })
        setSuccess('Profile updated successfully!')
        setError('')
      }
    } catch (error) {
      setError('Failed to update profile. Please try again.')
      setSuccess('')
    }
  }

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Update Profile</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="company" className="block mb-1">Company</label>
          <input
            type="text"
            id="company"
            {...register('company')}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
        <div>
          <label htmlFor="designation" className="block mb-1">Designation</label>
          <input
            type="text"
            id="designation"
            {...register('designation')}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
        <div>
          <label htmlFor="salary" className="block mb-1">Annual Salary (in INR LPA)</label>
          <input
            type="text"
            id="salary"
            {...register('salary')}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
        <button type="submit" className="w-full btn-primary">
          Update Profile
        </button>
      </form>
    </div>
  )
}

export default UpdateProfile